<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Fault E-Booklet Details
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
          <asom-form-field label="Fault Number">
            <p class="font-semibold">
              {{ get(itemData, "title", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Description">
            <p class="font-semibold">
              {{ get(itemData, "description", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Remarks">
            <p class="font-semibold">
              {{
                get(itemData, "remarks") == null
                  ? "NA"
                  : get(itemData, "remarks")
              }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="itemData.attachments.length > 0"
            label="Supporting Document"
          >
            <asom-upload-file-list
              :files="itemData.attachments"
              disabled-remove
            />
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { getFaultEBookletDetails } from "../../../services/faultManagement.service";

export default {
  name: "ViewOrder",
  data() {
    return {
      itemData: {
        title: this.$route.params.title,
        description: this.$route.params.description,
        remarks: this.$route.params.remarks,
        attachments: get(this.$route.params, "attachments", []),
      },
    };
  },
  mounted() {
    this.getEBookletDetails();
  },
  methods: {
    get,
    async getEBookletDetails() {
      const result = await getFaultEBookletDetails({
        faultEBookletId: get(this.$route.params, "faultEBookletId"),
      });
      if (result.success) {
        this.setFormData(get(result.payload, "viewModel"));
      }
    },
    setFormData(data) {
      this.itemData.title = get(data, "title", "-");
      this.itemData.description = get(data, "description", "-");
      this.itemData.remarks = get(data, "remarks", "-");
      this.itemData.attachments = get(data, "attachments", []);
    },
  },
};
</script>
